import React from "react"
import PropTypes from "prop-types"
import { Link, StaticQuery, graphql } from "gatsby"
import { Container } from 'react-bootstrap';

import Header from "./header"
import './bootstrap-umass.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
            author
            departmentUrl
            department
            institutionUrl
            institution
            wikiUrl
            osUrl
          }
        }
      }
    `}
    render={data => (
      <Container style={{ maxWidth: 960 }} >
        <Header siteTitle={data.site.siteMetadata.title}
                siteUrl={data.site.siteMetadata.siteUrl} />
        <main>{children}</main>
        <footer className='text-center'>
          <Link to="/">
            <strong>©{data.site.siteMetadata.author}</strong>
          </Link> |&nbsp;
          <a href={data.site.siteMetadata.departmentUrl} target="_blank" rel='noopener noreferrer'>
            {data.site.siteMetadata.department}
          </a> |&nbsp;
          <a href={data.site.siteMetadata.institutionUrl} target="_blank" rel='noopener noreferrer'>
            {data.site.siteMetadata.institution}
          </a> |&nbsp;
          <a href={data.site.siteMetadata.wikiUrl} target="_blank" rel='noopener noreferrer'>
            Group Wiki
          </a> |&nbsp;
          <a href={data.site.siteMetadata.osUrl} target="_blank" rel='noopener noreferrer'>
            Open Studio
          </a>
        </footer>
      </Container>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
