import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Button, Form, FormControl, Nav, Navbar } from 'react-bootstrap';

const Header = ({ siteTitle, siteUrl }) => (
  <header>
    <Navbar bg="light" expand="lg" collapseOnSelect>
      <Link to="/" className='navbar-brand' style={{color:"#212721"}}>{siteTitle}</Link>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Link to="/" className='nav-link'>Home</Link>
          <Link to="/people" className='nav-link'>Team</Link>
          <Link to="/research" className='nav-link'>Research</Link>
          <Link to="/publications" className='nav-link'>Publications</Link>
          <Link to="/teaching" className='nav-link'>Teaching</Link>
          <Link to="/openings" className='nav-link'>Openings</Link>
        </Nav>
        <Form action='https://www.google.com/search' method='get' role="search" inline>
          <FormControl type="text" name="q" placeholder="Search" className="mr-sm-2" />
          <Button type="submit" className="btn-link"></Button>
          <input type="hidden" name="sitesearch" value={siteUrl} />
        </Form>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
